import React, { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import { TextField, Button, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import { loginUser } from '../service/client-api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve the JWT token from localStorage
        const token = localStorage.getItem('token');
        // Redirect to home page if token is present
        if (token) {
            navigate('/');
        }
    }, [navigate]);

    const handleLogin = async () => {
        try {
            setLoading(true);
            const response = await loginUser(email, password);
            const { token } = response;
            localStorage.setItem('token', token);
            const decodedToken = jwtDecode(token);
            const userRole = decodedToken.role;
            navigate(userRole === 'admin' ? '/admin' : '/');
        } catch (error) {
            setLoading(false);
            console.error('Error during login:', error);
            toast.error('Invalid username or password');
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '120px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <TextField
                    label="Email or Phone"
                    variant="outlined"
                    color="warning"
                    style={{ marginBottom: '25px', width: '300px' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    color="warning"
                    variant="outlined"
                    style={{ marginBottom: '16px', width: '300px' }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    aria-label="toggle password visibility"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    style={{
                        width: '300px',
                        marginTop: '10px',
                        padding: '10px',
                        background: 'linear-gradient(to right, #ff7722, #ffaa44)',
                    }}>
                    Login
                </Button>
                <div>
                    <p>Don't have an account yet? <NavLink to="/register" style={{ textDecoration: 'none' }}><span style={{ color: '#ff7722' }}>Register</span></NavLink></p>
                    <p><NavLink to="/forget-password" style={{ textDecoration: 'none' }}><span style={{ color: '#ff7722' }}>Forget Password?</span></NavLink></p>
                </div>
            </div>
            <ToastContainer position="top-right" />
            {loading && <CircularProgress style={{ color: '#ff7722' }} />}
        </div>
    );
};

export default Login;
