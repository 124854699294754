import { useState } from 'react'
import { Drawer, List } from '@mui/material'
import { NavLink } from 'react-router-dom'
import {
  CloseOutlined, FacebookOutlined,
  TwitterOutlined, InstagramOutlined, LinkedinOutlined, ShareAltOutlined
} from '@ant-design/icons';

import LogOutPopUp from './logout-pop-up';

function LeftSideDrawer({ isDrawerOpen, setIsDrawerOpen }) {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Bharatiya Hindu Sangathan',
          url: window.location.href
        });
        console.log('Sharing successful');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.warn('Web Share API is not supported');
      // Fallback behavior or UI for browsers that do not support Web Share API
    }
  };


  return (

    <div>

      <Drawer anchor='left' open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>

        <div style={{ width: 270, background: 'linear-gradient(to right, #ff7722, #ffaa44)', color: 'white', height: '100%' }}>


          <List style={{ marginLeft: '20px', marginTop: '20px', display: 'block' }}>

            <CloseOutlined
              style={{
                fontSize: '20px',
                color: 'white',
                position: 'absolute',
                right: '10px',
                top: '5px',
                marginRight: '10px',
                cursor: 'pointer'
              }}
              // Close the drawer when clicked
              onClick={() => setIsDrawerOpen(false)}
            />

            <div onClick={() => {setIsDrawerOpen(false)}}>
              <NavLink to='/'
                style={{ textDecoration: 'none', color: 'inherit', marginLeft: '15px' }}>
                Home
              </NavLink>
              
            </div>

            <div style={{ marginTop: '15px' }} onClick={() => {setIsDrawerOpen(false)}}>

              <NavLink to='/about-us'
                style={{ textDecoration: 'none', color: 'inherit', marginLeft: '15px' }}>
                About Us
              </NavLink>

            </div>

            <div style={{ marginTop: '15px' }} onClick={() => {setIsDrawerOpen(false)}}>
              <NavLink to='/contact-us'
                style={{ textDecoration: 'none', color: 'inherit', marginLeft: '15px' }}>
                Contact Us
              </NavLink>

            </div>

            <div style={{ marginTop: '15px' }} onClick={() => {setIsDrawerOpen(false)}}>
              <NavLink to='/terms-conditions'
                style={{ textDecoration: 'none', color: 'inherit', marginLeft: '15px' }}>
                Terms & Conditions
              </NavLink>

            </div>

            <div style={{ marginTop: '15px' }} onClick={() => {setIsDrawerOpen(false)}}>
              <NavLink to='/privacy-policy'
                style={{ textDecoration: 'none', color: 'inherit', marginLeft: '15px' }}>
                Privacy & Policy
              </NavLink>

            </div>

            <div style={{ marginTop: '15px' }} onClick={() => {setIsDrawerOpen(false)}}>
              <NavLink to='/refund-policy'
                style={{ textDecoration: 'none', color: 'inherit', marginLeft: '15px' }}>
                Refund Policy
              </NavLink>

            </div>

            {/* <div style={{ marginTop: '15px' }}>
              <NavLink to='/product-lists'
                style={{ textDecoration: 'none', color: 'inherit', marginLeft: '15px' }}>
                Faq
              </NavLink>
            </div> */}

            <div
              style={{ marginTop: '15px', cursor: 'pointer', marginLeft: '15px' }}
              onClick={() => {
                setIsDrawerOpen(false); // Close the drawer
                setIsModalOpen(true); // Open the logout popup
              }}
            >
              Logout
            </div>


            <div style={{ display: 'flex', color: 'white', marginTop: '40px', marginLeft: '15px' }}>
              <FacebookOutlined style={{ fontSize: '30px', marginRight: '12px' }} />
              <TwitterOutlined style={{ fontSize: '30px', marginRight: '12px' }} />
              <InstagramOutlined style={{ fontSize: '30px', marginRight: '12px' }} />
              <LinkedinOutlined style={{ fontSize: '30px', marginRight: '12px' }} />

              <ShareAltOutlined
                style={{ fontSize: '30px', marginRight: '10px', cursor: 'pointer' }}
                onClick={handleShareClick}
              />
              
            </div>


          </List>

        </div>

      </Drawer>

      <LogOutPopUp isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>

  )
}

export default LeftSideDrawer