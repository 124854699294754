const stateList = [
    { id: 1, name: 'ANDAMAN AND NICOBAR ISLANDS' },
    { id: 2, name: 'ANDHRA PRADESH' },
    { id: 3, name: 'ARUNACHAL PRADESH' },
    { id: 4, name: 'ASSAM' },
    { id: 5, name: 'BIHAR' },
    { id: 6, name: 'CHANDIGARH' },
    { id: 7, name: 'CHHATTISGARH' },
    { id: 8, name: 'DADRA AND NAGAR HAVELI AND DAMAN AND DIU' },
    { id: 9, name: 'DELHI' },
    { id: 10, name: 'GOA' },
    { id: 11, name: 'GUJARAT' },
    { id: 12, name: 'HARYANA' },
    { id: 13, name: 'HIMACHAL PRADESH' },
    { id: 14, name: 'JAMMU AND KASHMIR' },
    { id: 15, name: 'JHARKHAND' },
    { id: 16, name: 'KARNATAKA' },
    { id: 17, name: 'KERALA' },
    { id: 18, name: 'LADAKH' },
    { id: 19, name: 'LAKSHADWEEP' },
    { id: 20, name: 'MADHYA PRADESH' },
    { id: 21, name: 'MAHARASHTRA' },
    { id: 22, name: 'MANIPUR' },
    { id: 23, name: 'MEGHALAYA' },
    { id: 24, name: 'MIZORAM' },
    { id: 25, name: 'NAGALAND' },
    { id: 26, name: 'ODISHA' },
    { id: 27, name: 'PUDUCHERRY' },
    { id: 28, name: 'PUNJAB' },
    { id: 29, name: 'RAJASTHAN' },
    { id: 30, name: 'SIKKIM' },
    { id: 31, name: 'TAMIL NADU' },
    { id: 32, name: 'TELANGANA' },
    { id: 33, name: 'TRIPURA' },
    { id: 34, name: 'UTTAR PRADESH' },
    { id: 35, name: 'UTTARAKHAND' },
    { id: 36, name: 'WEST BENGAL' }
];

export default stateList;
