import React from 'react';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

function LogOutPopUp({ isModalOpen, setIsModalOpen }) {

    const navigate = useNavigate();

    const handleOk = () => {
        localStorage.clear();
        setIsModalOpen(false);
        //window.location.reload(); // Reload the page
        navigate('/login');
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <Modal
                title="Warning!"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                okButtonProps={{ style: { background: 'linear-gradient(to right, #ff7722, #ffaa44)' } }} // Customize OK button background color
            //cancelButtonProps={{ style: { background: 'red', borderColor: 'red' } }} // Customize Cancel button background color
            >
                <p>"Are you sure you want to logout?"</p>
            </Modal>
        </div>
    );
}

export default LogOutPopUp;
