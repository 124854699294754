import axios from 'axios'
//const URL = "http://localhost:5000"
const URL = "https://bhs-org.onrender.com"

export const createRegistration = async (data) => {
    try {
        return await axios.post(`${URL}/create-registration`, data)

    } catch (error) {

        console.log('Error While Calling create-registration API', error)

    }
}

export const loginUser = async (email, password) => {

    try {

        const response = await axios.post(`${URL}/login`, { email, password });

        return response.data; // Assuming the server returns the JWT token upon successful login

    } catch (error) {

        console.log('Error While Calling loginUser API', error)

    }
}

export const getUserData = async () => {

    try {
        const token = localStorage.getItem('token');

        const response = await axios.get(`${URL}/user-data`, {

            headers: { Authorization: `Bearer ${token}` }
        });

        return response.data;

    } catch (error) {
        console.error('Error fetching user data:', error);
        throw new Error('Failed to fetch user data');
    }
};

export const getAllUserData = async (searchText, currentPage, limit) => {

    try {

        const response = await axios.get(`${URL}/view-users`, {
            params: {

                searchText,
                currentPage,
                limit,

            },
        });
        return response.data;

    } catch (error) {

        console.log('Error while calling User Data API', error)

    }
}


export const VerifyUserData = async (email, contactNo) => {
    try {
        const response = await axios.get(`${URL}/verify-user?email=${email}&contactNo=${contactNo}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return null; // Item not found
        }
        throw error;
    }
};


export const RazorPayCreateOrder = async (amount, currency, receiptId) => {

    try {
        const response = await axios.post(`${URL}/order`, {
            amount: amount,
            currency: currency,
            receipt: receiptId
        });

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const RazorPayOrderValidation = async ( razorpay_order_id, razorpay_payment_id, razorpay_signature ) => {
    
    try {
        const response = await axios.post(`${URL}/order-validation`, {

            razorpay_order_id: razorpay_order_id,
            razorpay_payment_id: razorpay_payment_id,
            razorpay_signature: razorpay_signature
        });

        return response.data;

    } catch (error) {

        console.log('client prob', error);
        throw error;
    }
}


