import React, { useEffect, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import { Paper, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';


const Home = () => {

    const [userName, setUserName] = useState('');
    const [greeting, setGreeting] = useState('');
    const navigate = useNavigate();
    const [sbutitle, setSubtitle] = useState('Mr/Miss');

    useEffect(() => {

        const token = localStorage.getItem('token');

        if (!token) {
            navigate('/login');
            return;
        }


        try {
            const decodedToken = jwtDecode(token);
            const { userName, gender } = decodedToken;
            setUserName(userName);


            if (gender === 'Male') {

                setSubtitle('Mr.')
            } else {
                setSubtitle('Ms.')
            }

            // Determine the time of the day and set the greeting message
            const hour = new Date().getHours();
            if (hour >= 5 && hour < 12) {
                setGreeting('Good Morning...');
            } else if (hour >= 12 && hour < 17) {
                setGreeting('Good Afternoon...');
            } else {
                setGreeting('Good Evening...');
            }
        } catch (error) {
            // Token is invalid
            alert('Invalid token. Please login again.');
            
        }

    }, [navigate]);


    // const amount = 500;
    // const currency = "INR";
    // const receiptId = "qwsaq1";


    // const paymentHandler = async (e) => {
    //     try {
    //         const response = await RazorPayCreateOrder(amount, currency, receiptId);

    //         const options = {
    //             "key": "rzp_test_znWpty3zToJ471",
    //             amount,
    //             currency,
    //             "name": "BHS ORG",
    //             "description": "Test Transaction",
    //             "image": "https://motionofknowledge.com/wp-content/uploads/2023/12/cropped-cropped-IMG-20231217-WA0004.jpg",
    //             "order_id": response.id,

    //             "handler": async function (response) {

    //                 const paymentStatusRes = await RazorPayOrderValidation(
    //                     response.razorpay_order_id,
    //                     response.razorpay_payment_id,
    //                     response.razorpay_signature
    //                 );

    //                 // If payment is successful, call API to save data to MongoDB
    //                 if (paymentStatusRes.msg === 'success') {
    //                     //await saveDataToMongo(dataToSave); // Replace with your actual function and data
    //                     toast.success('Payment Successful!');
    //                 } else {
    //                     // If payment failed, show error message
    //                     toast.error('Payment Not Done');
    //                 }

    //             },
    //             "prefill": {
    //                 "name": "Ravi Shankar",
    //                 "email": "kumarravi847203@gmail.com",
    //                 "contact": "8700598481"
    //             },
    //             "notes": {
    //                 "address": "Razorpay Corporate Office"
    //             },
    //             "theme": {
    //                 "color": "#ff7722"
    //             }
    //         };

    //         const rzp1 = new window.Razorpay(options);

    //         rzp1.on('payment.failed', function (response) {
    //             toast(response.error.code);
    //             toast(response.error.description);
    //             toast(response.error.source);
    //             toast(response.error.step);
    //             toast(response.error.reason);
    //             toast(response.error.metadata.order_id);
    //             toast(response.error.metadata.payment_id);
    //         });

    //         rzp1.open();
    //         e.preventDefault();
    //     } catch (error) {
    //         console.error('Payment failed:', error);
    //         // Handle payment failure
    //         toast.error('Payment Not Done');
    //     }
    // };



    return (
        <div style={{ padding: '0px 10px 0px 10px' }}>

            <div style={{ marginLeft: '10px', marginTop: '80px' }}>
                <h2>{greeting}</h2>
                <p>{sbutitle} {userName}</p>
            </div>

            <div style={{ marginTop: '25px' }}>
                <Paper sx={{ padding: 2 }} style={{ background: 'linear-gradient(to right, #ff7722, #ffaa44)', }}>

                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        <div style={{ flex: '1', color: 'white' }}>

                            <Typography variant="h6" gutterBottom>
                                Naval Kishor
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                8700598481
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Adarsh Nagar New Delhi - 110033
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                School Fees
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                INR: 50,000
                            </Typography>
                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img
                                src={'https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg'}
                                alt="Profile Pic"
                                style={{
                                    width: 100,
                                    height: 100,
                                    // borderRadius: '50%', // Apply rounded corners to make the image circular
                                    objectFit: 'cover' // Ensure the image covers the entire circular space
                                }}
                            />

                            {/* <div style={{ marginTop: 10 }}>
                                <a href="upi://pay?pa=8700598481@icici">
                                    <Button style={{ background: '#f0f0f0', color: 'black', fontSize: '10px', paddingLeft: '20px', paddingRight: '20px' }}>Pay Now!</Button>
                                </a>
                            </div> */}
                        </div>


                    </div>

                </Paper>
            </div>

            {/* <div className="product">
                <h2>Tshirt</h2>
                <p>Solid blue cotton Tshirt</p>

                <div style={{ width: '350px', height: '50vh' }}>
                    <img
                        src={"https://www.mydesignation.com/wp-content/uploads/2020/02/golden-yellow-plain-tshirt-mydesignation.jpg"}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        alt="T-shirt"
                    />
                </div>


                <br />
                <button onClick={'paymentHandler'}>Pay</button>
            </div> */}


        </div>
    );
};

export default Home;
