const occupationList = [
    { id: 1, name: 'Doctor' },
    { id: 2, name: 'Engineer' },
    { id: 3, name: 'Businessman' },
    { id: 4, name: 'Teacher' },
    { id: 5, name: 'Lawyer' },
    { id: 6, name: 'Accountant' },
    { id: 7, name: 'Civil Servant' },
    { id: 8, name: 'Farmer' },
    { id: 9, name: 'Banker' },
    { id: 10, name: 'Architect' },
    { id: 11, name: 'Artist' },
    { id: 12, name: 'Musician' },
    { id: 13, name: 'Writer' },
    { id: 14, name: 'Journalist' },
    { id: 15, name: 'Actor' },
    { id: 16, name: 'Director' },
    { id: 17, name: 'Producer' },
    { id: 18, name: 'Chef' },
    { id: 19, name: 'Pilot' },
    { id: 20, name: 'Entrepreneur' },
  ];
  
  export default occupationList;
  