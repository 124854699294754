import React, { useEffect, useState } from 'react';
import { getUserData } from '../service/client-api';
import { Paper, Typography, CircularProgress} from '@mui/material';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const Profile = () => {

    const [userData, setUserData] = useState(null);
    const [greeting, setGreeting] = useState('');
    const [userName, setUserName] = useState('');
    const [sbutitle, setSubtitle] = useState('Mr/Miss');
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate();


    useEffect(() => {

        // Retrieve the JWT token from localStorage
        const token = localStorage.getItem('token');


        // Redirect to login page if token is not present
        if (!token) {
            navigate('/login');
            return;
        }

        // Decode the JWT token to extract the role
        const decodedToken = jwtDecode(token);
        const { role, gender } = decodedToken;

        // Redirect to home page if role is not admin
        if (role === 'admin') {
            navigate('/admin');
            return;
        }

        if (gender === 'Male') {

            setSubtitle('Mr.')
        } else {
            setSubtitle('Ms.')
        }


        const fetchData = async () => {
            try {
                setLoading(true)

                const data = await getUserData();
                setUserData(data);

                setLoading(false)
            } catch (error) {
                console.error('Error:', error.message);
            }
        };

        fetchData();



        if (token) {

            // Decode the JWT token to extract the userName
            const decodedToken = jwtDecode(token);
            const { userName } = decodedToken; // Assuming the userName is stored in the JWT token

            setUserName(userName);
        }



        // Determine the time of the day and set the greeting message
        const hour = new Date().getHours();
        if (hour >= 5 && hour < 12) {
            setGreeting('Good Morning...');
        } else if (hour >= 12 && hour < 17) {
            setGreeting('Good Afternoon...');
        } else {
            setGreeting('Good Evening...');
        }

    }, [navigate]);

    return (
        <div>


            <div style={{ padding: '0px 10px 0px 10px' }}>

                <div style={{ marginLeft: '10px', marginTop: '80px' }}>
                    <h2>{greeting}</h2>
                    <p>{sbutitle} {userName}</p>
                </div>

                <div style={{ marginTop: '25px' }}>

                    {userData && (

                        <Paper sx={{ padding: 2 }} style={{ background: 'linear-gradient(to right, #ff7722, #ffaa44)', }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ flex: '1', maxWidth: 'calc(100% - 120px)', color: 'white' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {userData.userDetails.name.toUpperCase()}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {userData.userDetails.contactNo}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {userData.userDetails.gender}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {userData.userDetails.dob}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {userData.userDetails.occupation}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {userData.paymentDetails.donationId ? userData.paymentDetails.donationId : 'N/A'}
                                    </Typography>

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img
                                        src={`${userData.userPic.profilePic}`}
                                        alt="Profile Pic"
                                        style={{
                                            width: 100,
                                            height: 100,
                                            borderRadius: '50%', // Apply rounded corners to make the image circular
                                            objectFit: 'cover' // Ensure the image covers the entire circular space
                                        }}
                                    />

                                </div>




                            </div>
                        </Paper>
                    )}

                </div>



                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {loading && <CircularProgress style={{ color: '#ff7722' }} />}
                </div>


            </div>
        </div>
    );
};

export default Profile;
