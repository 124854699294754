import React, { useEffect, useState } from 'react';
import { getAllUserData } from '../service/client-api';
import { Paper, Typography, CircularProgress } from '@mui/material';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'antd';

import '../css/admin.css';

const Admin = () => {
    const [userData, setUserData] = useState([]);
    const [greeting, setGreeting] = useState('');
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [sbutitle, setSubtitle] = useState('Mr/Miss');

    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getAllUserData(searchText, currentPage, 10);
                setUserData(response.data.reverse());
                setTotalItems(response.totalCount);
            } catch (error) {
                console.error('Client Side Component: Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [searchText, currentPage]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        const decodedToken = jwtDecode(token);
        const { role, userName, gender } = decodedToken;
        if (role !== 'admin') {
            navigate('/');
            return;
        }

        setUserName(userName);
        setSubtitle(gender === 'Male' ? 'Mr.' : 'Ms.');

        const hour = new Date().getHours();
        if (hour >= 5 && hour < 12) {
            setGreeting('Good Morning...');
        } else if (hour >= 12 && hour < 17) {
            setGreeting('Good Afternoon...');
        } else {
            setGreeting('Good Evening...');
        }
    }, [navigate]);

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    };

    const handlePaginationChange = (page) => {
        setCurrentPage(page);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>

            <div style={{
                position: 'sticky', top: '55px', zIndex: '100',
                background: 'white', paddingLeft: '10px', paddingRight: '10px'
            }}>
                <div style={{ marginLeft: '10px', paddingTop: '2px' }}>
                    <h2>{greeting}</h2>
                    <p>{sbutitle} {userName}</p>
                </div>
                <div>
                    <input
                        type="text"
                        placeholder="Search..."
                        style={{
                            padding: '11px', width: '100%', boxSizing: 'border-box',
                            marginBottom: '15px', marginRight: '0px', borderRadius: '15px', borderColor: '#ff7722'
                        }}
                        onChange={handleSearchTextChange}
                    />
                </div>
            </div>

            <div className='admin-top2' style={{ marginTop: '60px', paddingLeft: '10px', paddingRight: '10px' }}>

                <div style={{ marginBottom: '30px' }}>

                    {userData && userData.length > 0 ? (
                        userData.map(user => (
                            <Paper key={user._id} sx={{ padding: 2 }} style={{ background: 'linear-gradient(to right, #ff7722, #ffaa44)', marginTop: '10px' }}>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: '1', maxWidth: 'calc(100% - 120px)', color: 'white' }}>
                                        <Typography variant="h6" gutterBottom>
                                            {user.userDetails.name.toUpperCase()}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {user.userDetails.contactNo}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {user.userDetails.gender}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {user.userDetails.dob}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {user.userDetails.occupation}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {user.paymentDetails.donationId ? user.paymentDetails.donationId : 'N/A'}
                                        </Typography>
                                    </div>
                                    <img
                                        src={`${user.userPic.profilePic}`}
                                        alt="Profile Pic"
                                        style={{
                                            width: 100,
                                            height: 100,
                                            marginLeft: 20,
                                            borderRadius: '50%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                            </Paper>
                        ))
                    ) : (
                        <Typography variant="body1" gutterBottom>
                            No user data available.
                        </Typography>
                    )}
                </div>

                <div className="admin-pagination-container" style={{ marginBottom: '100px', textAlign: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        pageSize={10}
                        onChange={handlePaginationChange}
                        className="custom-pagination"
                    />
                </div>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {loading && <CircularProgress style={{ color: '#ff7722' }} />}
            </div>
        </div>
    );
};

export default Admin;
