import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import {
    HomeOutlined,
    UserOutlined, VerticalAlignTopOutlined,
    VerticalAlignBottomOutlined
} from '@ant-design/icons';
import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';

// import { useNavigate } from 'react-router-dom';
// import { jwtDecode } from "jwt-decode";

import '../css/bottom-bar.css';

function BottomNavigationPage() {

    const [value, setValue] = useState(0);
    // const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (

        
            <div className='bottom-navigation' style={{display: 'none'}}>

                <Box sx={{ position: 'fixed', bottom: 0, width: '100vw', zIndex: 1000, marginLeft: '-8px' }}>

                    <BottomNavigation showLabels value={value} onChange={handleChange}

                        style={{ background: 'linear-gradient(to right, #ff7722, #ffaa44)', height: '70px' }}>

                        <BottomNavigationAction
                            label="Home"
                            icon={
                                <NavLink to="/" style={{ textDecoration: 'none', color: 'white' }}>
                                    <HomeOutlined style={{ color: 'white', fontSize: 20, marginBottom: '8px' }} />
                                </NavLink>
                            }
                            style={{ color: 'white', paddingTop: '10px', paddingBottom: '10px' }}
                        />

                        <BottomNavigationAction
                            label="Donate"
                            icon={

                                <VerticalAlignBottomOutlined style={{ color: 'white', fontSize: 20, marginBottom: '8px' }} />

                            }
                            style={{ color: 'white', paddingTop: '10px', paddingBottom: '10px' }}
                        />

                        <BottomNavigationAction
                            label="Request"
                            icon={

                                <VerticalAlignTopOutlined style={{ color: 'white', fontSize: 20, marginBottom: '8px' }} />

                            }
                            style={{ color: 'white', paddingTop: '10px', paddingBottom: '10px' }}
                        />

                        <BottomNavigationAction
                            label="User"
                            icon={

                                <NavLink to="/profile" style={{ textDecoration: 'none', color: 'white' }}>

                                    <UserOutlined
                                        // onClick={handleProfileButtonClick()}
                                        style={{ color: 'white', fontSize: 20, marginBottom: '8px' }} />

                                </NavLink>


                            }
                            style={{ color: 'white', paddingTop: '10px', paddingBottom: '10px' }}
                        />

                    </BottomNavigation>
                </Box>
            </div>

    );
}

export default BottomNavigationPage;
