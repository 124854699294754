import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';


import NavBar from './components/nav-bar';
import Home from './pages/home';
import Profile from './pages/profile';
import BottomBar from './components/bottom-bar'
import Login from './pages/login';
import Register from './pages/register';
import Admin from './admin/admin';
import ForgetPassword from './pages/forget-password';
import AboutUs from './pages/about-us';
import ContactUs from './pages/contact-us';
import TermsConditions from './pages/terms-condition'
import PrivacyPolicy from './pages/privacy-policy'
import RefundPolicy from './pages/refund-policy'


function App() {
  return (
    <Router>

      <NavBar />
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        
      </Routes>
      <BottomBar/>
    </Router>
  );
}

export default App;
