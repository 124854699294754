import { StepIcon } from '@mui/material';

const CustomStepIcon = (props) => {
  const { active, completed } = props;

  let color = ''; // Set default color

  if (active) {
    color = '#ffaa44'; // Set the color for the active step
  } else if (completed) {
    color = '#ff7722'; // Set the color for completed steps
  }

  return <StepIcon {...props} style={{ color }} />;
};

export default CustomStepIcon;
