import { useState } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { MenuOutlined} from '@ant-design/icons';
import LeftSideDrawer from './left-side-drawer';

const NavBar = () => {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (

        <AppBar position='fixed' style={{ background: 'linear-gradient(to right, #ff7722, #ffaa44)' }}>
            <Toolbar style={{ justifyContent: 'space-between' }}>
                <Typography variant="h6" component={Link} to="/" style={{ textDecoration: 'none', color: 'white' }}>
                    Bharatiya Hindu Sangathan
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }} />
                    <MenuOutlined
                        style={{
                            fontSize: '20px',
                            color: 'white',
                            marginRight: '10px',
                            cursor: 'pointer'
                        }}

                        // Open the drawer when clicked
                        onClick={() => setIsDrawerOpen(true)}
                    />
                </div>
            </Toolbar>

            {/* Pass props to LeftSideDrawer */}
            <LeftSideDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />

        </AppBar>

    );
};

export default NavBar;
