import React, { useState, useEffect } from 'react';
import {
    Stepper, Step, StepLabel, Button, Typography, TextField,
    Box, Autocomplete, CircularProgress,IconButton, InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// import { PictureOutlined } from '@ant-design/icons';
import { uploadFile } from '../service/file-upload-api'

import { NavLink } from 'react-router-dom';
import { createRegistration, VerifyUserData } from '../service/client-api'
import CustomStepIcon from '../components/custom-step-icon';
import occupationList from '../support/occupation-list';
import genderList from '../support/gender-list';
import stateList from '../support/state-list';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
//import { jwtDecode } from "jwt-decode";

// const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];
const steps = ['Step 1', 'Step 2', 'Step 3'];


const StepOne = ({ formData, setFormData, handleInputChange }) => {

    const [showPassword, setShowPassword] = useState(false);

    return (


        <Box>


            <TextField
                name="userDetails.email"
                label="Email or Phone"
                variant="outlined"
                color="warning"
                value={formData.userDetails.email}
                onChange={handleInputChange}
                fullWidth
                style={{ marginBottom: '20px' }}

            />

            <TextField
                name="userDetails.password"
                label="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                color="warning"
                value={formData.userDetails.password}
                onChange={handleInputChange}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                aria-label="toggle password visibility"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}

            />


        </Box>
    );

};

const StepTwo = ({ formData, setFormData,
    handleInputChange, selectedOccupation,
    setSelectedOccupation, selectedGender, setSelectedGender, selectedState, setSelectedState }) => {


    const handleOccupationChange = (event, value) => {
        setSelectedOccupation(value);

    };

    const handleStateChange = (event, value) => {
        setSelectedState(value);

    };

    const handleGenderChange = (event, value) => {
        setSelectedGender(value);

    };

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            userDetails: {
                ...prevFormData.userDetails,
                occupation: selectedOccupation ? selectedOccupation.name : '',
                gender: selectedGender ? selectedGender.name : '',
                state: selectedState ? selectedState.name : '',

            },
        }));
    }, [selectedOccupation, selectedGender, setFormData, selectedState]);

    return (


        <Box>


            <TextField
                name="userDetails.name"
                label="Full Name"
                variant="outlined"
                color="warning"
                value={formData.userDetails.name}
                onChange={handleInputChange}
                fullWidth
                style={{ marginBottom: '20px' }}

            />

            <TextField
                name="userDetails.contactNo"
                label="Contact No"
                variant="outlined"
                color="warning"
                value={formData.userDetails.contactNo}
                onChange={handleInputChange}
                fullWidth
                style={{ marginBottom: '20px' }}

            />

            {/* <TextField
                name="userDetails.dob"
                label="Date of Birth"
                variant="outlined"
                color="warning"
                value={formData.userDetails.dob}
                onChange={handleInputChange}
                fullWidth
                type='date'
                style={{ marginBottom: '20px' }}
                InputLabelProps={{ shrink: true }}

            /> */}

            {/* <TextField
                name="userDetails.aadharNo"
                label="Aadhar No"
                variant="outlined"
                color="warning"
                value={formData.userDetails.aadharNo}
                onChange={handleInputChange}
                fullWidth
                style={{ marginBottom: '20px' }}

            /> */}

            <Autocomplete
                value={selectedGender}
                onChange={handleGenderChange}
                options={genderList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Gender"
                        variant="outlined"
                        color="warning"
                        fullWidth

                    />
                )}
                style={{ marginBottom: '20px' }}
            />


            <Autocomplete
                value={selectedOccupation}
                onChange={handleOccupationChange}
                options={occupationList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Occupation"
                        variant="outlined"
                        color="warning"
                        fullWidth

                    />
                )}
                style={{ marginBottom: '20px' }}
            />

            <TextField
                name="userDetails.city"
                label="City Name"
                variant="outlined"
                color="warning"
                value={formData.userDetails.city}
                onChange={handleInputChange}
                fullWidth
                style={{ marginBottom: '20px' }}

            />

            <Autocomplete
                value={selectedState}
                onChange={handleStateChange}
                options={stateList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="State"
                        variant="outlined"
                        color="warning"
                        fullWidth

                    />
                )}
                style={{ marginBottom: '40px' }}
            />


        </Box>
    );

};

// const StepThree = ({ formData, setFormData, handleInputChange,
//     childOneSelectedGender, setChildOneSelectedGender,
//     childTwoSelectedGender, setChildTwoSelectedGender,
//     childThreeSelectedGender, setChildThreeSelectedGender }) => {


//     const handleGenderChangeOne = (event, value) => {
//         setChildOneSelectedGender(value);

//     };

//     const handleGenderChangeTwo = (event, value) => {
//         setChildTwoSelectedGender(value);

//     };

//     const handleGenderChangeThree = (event, value) => {
//         setChildThreeSelectedGender(value);

//     };

//     useEffect(() => {
//         setFormData((prevFormData) => ({
//             ...prevFormData,
//             childOne: {
//                 ...prevFormData.childOne,

//                 gender: childOneSelectedGender ? childOneSelectedGender.name : '',

//             },

//             childTwo: {
//                 ...prevFormData.childTwo,

//                 gender: childTwoSelectedGender ? childTwoSelectedGender.name : '',

//             },

//             childThree: {
//                 ...prevFormData.childThree,

//                 gender: childThreeSelectedGender ? childThreeSelectedGender.name : '',

//             },
//         }));
//     }, [childOneSelectedGender, childTwoSelectedGender, childThreeSelectedGender, setFormData]);


//     return (


//         <Box>

//             <div>

//                 <div style={{
//                     textAlign: 'left', marginBottom: '10px',
//                     fontSize: '18px', marginLeft: '10px', fontWeight: '500'
//                 }}>Child I</div>
//                 <TextField
//                     name="childOne.name"
//                     label="Full Name"
//                     variant="outlined"
//                     color="warning"
//                     value={formData.childOne.name}
//                     onChange={handleInputChange}
//                     fullWidth
//                     style={{ marginBottom: '20px' }}

//                 />

//                 <TextField
//                     name="childOne.dob"
//                     label="Date of Birth"
//                     variant="outlined"
//                     color="warning"
//                     value={formData.childOne.dob}
//                     onChange={handleInputChange}
//                     fullWidth
//                     type='date'
//                     style={{ marginBottom: '20px' }}
//                     InputLabelProps={{ shrink: true }}

//                 />

//                 <Autocomplete
//                     value={childOneSelectedGender}
//                     onChange={handleGenderChangeOne}
//                     options={genderList}
//                     getOptionLabel={(option) => option.name}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             label="Gender"
//                             variant="outlined"
//                             color="warning"
//                             fullWidth

//                         />
//                     )}
//                     style={{ marginBottom: '20px' }}
//                 />

//             </div>

//             <div style={{ marginTop: '20px' }}>

//                 <div style={{
//                     textAlign: 'left', marginBottom: '10px',
//                     fontSize: '18px', marginLeft: '10px', fontWeight: '500'
//                 }}>Child II</div>
//                 <TextField
//                     name="childTwo.name"
//                     label="Full Name"
//                     variant="outlined"
//                     color="warning"
//                     value={formData.childTwo.name}
//                     onChange={handleInputChange}
//                     fullWidth
//                     style={{ marginBottom: '20px' }}


//                 />

//                 <TextField
//                     name="childTwo.dob"
//                     label="Date of Birth"
//                     variant="outlined"
//                     color="warning"
//                     value={formData.childTwo.dob}
//                     onChange={handleInputChange}
//                     fullWidth
//                     type='date'
//                     style={{ marginBottom: '20px' }}
//                     InputLabelProps={{ shrink: true }}

//                 />

//                 <Autocomplete
//                     value={childTwoSelectedGender}
//                     onChange={handleGenderChangeTwo}
//                     options={genderList}
//                     getOptionLabel={(option) => option.name}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             label="Gender"
//                             variant="outlined"
//                             color="warning"
//                             fullWidth

//                         />
//                     )}
//                     style={{ marginBottom: '20px' }}
//                 />

//             </div>

//             <div style={{ marginTop: '20px' }}>

//                 <div style={{
//                     textAlign: 'left', marginBottom: '10px',
//                     fontSize: '18px', marginLeft: '10px', fontWeight: '500'
//                 }}>Child III</div>
//                 <TextField
//                     name="childThree.name"
//                     label="Full Name"
//                     variant="outlined"
//                     color="warning"
//                     value={formData.childThree.name}
//                     onChange={handleInputChange}
//                     fullWidth
//                     style={{ marginBottom: '20px' }}


//                 />

//                 <TextField
//                     name="childThree.dob"
//                     label="Date of Birth"
//                     variant="outlined"
//                     color="warning"
//                     value={formData.childThree.dob}
//                     onChange={handleInputChange}
//                     fullWidth
//                     type='date'
//                     style={{ marginBottom: '20px' }}
//                     InputLabelProps={{ shrink: true }}

//                 />

//                 <Autocomplete
//                     value={childThreeSelectedGender}
//                     onChange={handleGenderChangeThree}
//                     options={genderList}
//                     getOptionLabel={(option) => option.name}
//                     renderInput={(params) => (
//                         <TextField
//                             {...params}
//                             label="Gender"
//                             variant="outlined"
//                             color="warning"
//                             fullWidth

//                         />
//                     )}
//                     style={{ marginBottom: '20px' }}
//                 />

//             </div>








//         </Box>
//     );

// };

const NewStep = ({ setSelectedFileNewProfilePic }) => {


    const handleFileChangeNewProfilePic = (event) => {

        // Handle file selection here
        const file = event.target.files[0];

        setSelectedFileNewProfilePic(file);

    };


    return (


        <div style={{ marginTop: '-10px', marginBottom: '25px', alignItems: 'left', justifyContent: 'center' }}>

            <p style={{textAlign: 'left', marginLeft: '10px', marginTop: '50px', fontSize: '16px'}}> Upload Profile Pic</p>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '25px',
                marginBottom: '50px',
                border: '2px dashed #ff7722',
                borderRadius: '8px',
                padding: '35px',
                // Set a specific width
                margin: '0 auto' // Center horizontally
            }}>

                <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChangeNewProfilePic}
                    style={{ display: 'block'}}
                />
            </div>
        </div>


    );

};

// const StepFour = ({ setSelectedFileAadhar, setSelectedFileProfilePic, selectedFileAadhar, selectedFileProfilePic }) => {


//     const handleFileChangeAadhar = (event) => {

//         // Handle file selection here
//         const file = event.target.files[0];

//         setSelectedFileAadhar(file);

//     };

//     const handleFileChangeProfilePic = (event) => {

//         // Handle file selection here
//         const file = event.target.files[0];

//         setSelectedFileProfilePic(file);
//     };


//     return (


//         <div style={{ marginTop: '-10px', display: 'flex', marginBottom: '25px', alignItems: 'center', justifyContent: 'center' }}>

//             <div style={{ display: 'flex', marginTop: '20px', flexWrap: 'wrap' }}>

//                 <label htmlFor="file-input">
//                     <Paper
//                         elevation={3}
//                         style={{
//                             position: 'relative',
//                             width: '150px',
//                             height: '150px',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             cursor: 'pointer',
//                         }}
//                     >
//                         {selectedFileAadhar ? (
//                             <img
//                                 src={URL.createObjectURL(selectedFileAadhar)}
//                                 alt="Selected"
//                                 style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                             />
//                         ) : (
//                             <PictureOutlined style={{ fontSize: 60 }} />
//                         )}
//                         <Typography
//                             variant="body2"
//                             style={{
//                                 position: 'absolute',
//                                 top: 0,
//                                 left: 0,
//                                 margin: '5px',
//                                 backgroundColor: 'rgba(255, 255, 255, 0.8)',
//                                 padding: '2px 5px',
//                                 borderRadius: '3px',
//                             }}
//                         >
//                             Aadhar Card
//                         </Typography>
//                     </Paper>
//                 </label>
//                 <input
//                     id="file-input"
//                     type="file"
//                     accept="image/*"
//                     onChange={handleFileChangeAadhar}
//                     style={{ display: 'none' }}
//                 />

//             </div>

//             <div style={{ display: 'flex', marginTop: '20px', flexWrap: 'wrap', marginLeft: '15px' }}>

//                 <label htmlFor="file-input2">
//                     <Paper
//                         elevation={3}
//                         style={{
//                             position: 'relative',
//                             width: '150px',
//                             height: '150px',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             cursor: 'pointer',
//                         }}
//                     >
//                         {selectedFileProfilePic ? (
//                             <img
//                                 src={URL.createObjectURL(selectedFileProfilePic)}
//                                 alt="Selected"
//                                 style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                             />
//                         ) : (
//                             <PictureOutlined style={{ fontSize: 60 }} />
//                         )}
//                         <Typography
//                             variant="body2"
//                             style={{
//                                 position: 'absolute',
//                                 top: 0,
//                                 left: 0,
//                                 margin: '5px',
//                                 backgroundColor: 'rgba(255, 255, 255, 0.8)',
//                                 padding: '2px 5px',
//                                 borderRadius: '3px',
//                             }}
//                         >
//                             Profile Pic
//                         </Typography>
//                     </Paper>
//                 </label>
//                 <input
//                     id="file-input2"
//                     type="file"
//                     accept="image/*"
//                     onChange={handleFileChangeProfilePic}
//                     style={{ display: 'none' }}
//                 />

//             </div>

//         </div>

//     );

// };

// const StepFive = ({ setFormData, setSelectedFileTransaction }) => {


//     const handleFileChangeTransaction = (event) => {

//         // Handle file selection here
//         const file = event.target.files[0];

//         setSelectedFileTransaction(file);
//     };


//     return (


//         <div style={{ marginTop: '-10px', marginBottom: '25px', alignItems: 'center', justifyContent: 'center' }}>

//             <div style={{ marginTop: '20px', flexWrap: 'wrap' }}>
//                 <img
//                     src={"payment_qr_code.jpeg"} // Use the imported image directly
//                     alt="YourImage"
//                     style={{ width: '300px', height: '400px' }}
//                 />
//             </div>

//             <div style={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 marginTop: '20px',
//                 border: '2px dashed #ff7722',
//                 borderRadius: '8px',
//                 padding: '35px',
//                 // Set a specific width
//                 margin: '0 auto' // Center horizontally
//             }}>

//                 <input
//                     id="fileInput"
//                     type="file"
//                     accept="image/*"
//                     onChange={handleFileChangeTransaction}
//                     style={{ display: 'block' }}
//                 />
//             </div>
//         </div>


//     );

// };

const Register = () => {

    const [activeStep, setActiveStep] = useState(0);

    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedOccupation, setSelectedOccupation] = useState(null);
    const [selectedState, setSelectedState] = useState(null);


    // const [childOneSelectedGender, setChildOneSelectedGender] = useState(null);
    // const [childTwoSelectedGender, setChildTwoSelectedGender] = useState(null);
    // const [childThreeSelectedGender, setChildThreeSelectedGender] = useState(null);

    // const [selectedFileAadhar, setSelectedFileAadhar] = useState(null);
    // const [selectedFileProfilePic, setSelectedFileProfilePic] = useState(null);
    // const [selectedFileTransaction, setSelectedFileTransaction] = useState(null);

    const [selectedFileNewProfilePic, setSelectedFileNewProfilePic] = useState(null);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        // Retrieve the JWT token from localStorage
        const token = localStorage.getItem('token');


        // Redirect to login page if token is not present
        if (token) {
            navigate('/');
            return;
        }


    }, [navigate]);

    const handleNext = () => {

        // // Save formData to localStorage
        // localStorage.setItem('formData', JSON.stringify(formData));

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        window.scrollTo({
            top: 0,
            behavior: 'smooth' // You can change this to 'auto' for instant scrolling
        });


    };

    const handleBack = () => {


        setActiveStep((prevActiveStep) => prevActiveStep - 1);

        window.scrollTo({
            top: 0,
            behavior: 'smooth' // You can change this to 'auto' for instant scrolling
        });



    };

    const handleInputChange = (e) => {

        const { name, value } = e.target;

        // Splitting the name to access nested properties
        const [mainKey, nestedKey] = name.split('.');

        setFormData((prevFormData) => ({
            ...prevFormData,
            [mainKey]: {
                ...prevFormData[mainKey],
                [nestedKey]: value,
            },
        }));
    };


    // Initialize formData state with localStorage data if available
    const initialFormData = localStorage.getItem('formData')

        ? JSON.parse(localStorage.getItem('formData'))
        : {
            userDetails: {
                email: '',
                password: '',
                name: '',
                contactNo: '',
                // dob: '',
                // aadharNo: '',
                gender: '',
                occupation: '',
                role: 'user',
                city: '',
                state: '',
            },
            childOne: {
                name: '',
                gender: '',
                dob: ''
            },
            childTwo: {
                name: '',
                gender: '',
                dob: ''
            },
            childThree: {
                name: '',
                gender: '',
                dob: ''
            },
            userPic: {
                profilePic: '',
                aadharCard: ''
            },
            paymentDetails: {
                razorpay_order_id: '',
                razorpay_payment_id: '',
                razorpay_signature: '',
                donationId: '',
                transaction: '',
            }
        };

    const [formData, setFormData] = useState(initialFormData);


    // Update localStorage whenever formData changes
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);


    const handleFileUpload = async () => {
        try {

            setLoading(true);

            // const uploadedFileUrlAadhar = await uploadFile(selectedFileAadhar);
            // const uploadedFileUrlProfile = await uploadFile(selectedFileProfilePic);
            // const uploadFileUrlTransaction = await uploadFile(selectedFileTransaction)
            const uploadFileUrlNewProfilePic = await uploadFile(selectedFileNewProfilePic)

            // Append the form data with the Aadhar card and profile picture URLs
            // formData.userPic.aadharCard = uploadedFileUrlAadhar;
            // formData.userPic.profilePic = uploadedFileUrlProfile;
            // formData.paymentDetails.transaction = uploadFileUrlTransaction;

            formData.userPic.profilePic = uploadFileUrlNewProfilePic;

        } catch (error) {
            console.error('Error during file upload:', error.message);
            // Handle file upload error
            toast.error('File upload failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Function to generate custom order ID using current date and time
    // const generateReceiptId = () => {
    //     const now = new Date();
    //     const receiptId = `ORDER-${now.getTime()}`; // Format: ORDER-<timestamp>
    //     return receiptId;
    // };

    // Function to generate custom donation ID using current date and time
    const generateDonationId = () => {
        const now = new Date();
        const timestamp = now.getTime().toString().slice(-4); // Extract last four digits of timestamp
        const donationId = `BHS-2024-${timestamp}`; // Format: BHS-2024-<last_four_digits_of_timestamp>
        return donationId;
    };


    // const paymentHandler = async (e) => {

    //     try {

    //         setLoading(true);


    //         // Validate if any required field is empty
    //         const requiredFields = {
    //             ...formData.userDetails,

    //         };

    //         if (Object.values(requiredFields).some(value => value === '')) {
    //             toast.error('All Fileds are Required!')
    //             setLoading(false)
    //             return
    //         }

    //         if (selectedFileProfilePic === null || selectedFileAadhar === null) {
    //             toast.error('Please select Aadhar and Profile pictures');
    //             setLoading(false);
    //             return;
    //         }

    //         // Check if user already exists
    //         const userData = await VerifyUserData(formData.userDetails.email, formData.userDetails.contactNo);

    //         if (userData) {
    //             setLoading(false);
    //             toast.error('Already Registered');
    //             return;
    //         }

    //         // Generate custom order ID using current date and time
    //         const receiptId = generateReceiptId();

    //         const donationId = generateDonationId();


    //         //first create an order 
    //         const response = await RazorPayCreateOrder(2000, "INR", receiptId);

    //         const options = {

    //             "key": "rzp_test_znWpty3zToJ471",
    //             "amount": 2000,
    //             "currency": "INR",
    //             "name": "BHS ORG",
    //             "description": "Donation",
    //             "image": "https://inventory-thescrapx.s3.ap-south-1.amazonaws.com/icons/BHS.png",
    //             "order_id": response.id,

    //             "handler": async function (response) {

    //                 const paymentStatusRes = await RazorPayOrderValidation(
    //                     response.razorpay_order_id,
    //                     response.razorpay_payment_id,
    //                     response.razorpay_signature

    //                 );

    //                 // Append the form data with the Aadhar card and profile picture URLs
    //                 formData.paymentDetails.razorpay_order_id = response.razorpay_order_id;
    //                 formData.paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
    //                 formData.paymentDetails.razorpay_signature = response.razorpay_signature;

    //                 formData.paymentDetails.donationId = donationId;


    //                 // If payment is successful, call API to save data to MongoDB
    //                 if (paymentStatusRes.msg === 'success') {

    //                     await handleFileUpload();

    //                     // Proceed with the registration process
    //                     await createRegistration(formData);

    //                     setLoading(false)

    //                     toast.success('Registration successful!');

    //                     // Delete saved formData from localStorage
    //                     localStorage.removeItem('formData');

    //                     //console.log('new form', formData);

    //                     setTimeout(() => {
    //                         navigate('/login');
    //                     }, 2000); // Adjust the delay time as needed (in milliseconds)


    //                 } else {

    //                     // If payment failed, show error message
    //                     toast.error('Payment Not Done');
    //                     setLoading(false)
    //                 }


    //             },
    //             "prefill": {
    //                 "name": formData.userDetails.name,
    //                 "email": formData.userDetails.email,
    //                 "contact": formData.userDetails.contactNo
    //             },
    //             "notes": {
    //                 "address": "Razorpay Corporate Office"
    //             },
    //             "theme": {
    //                 "color": "#ff7722"
    //             }
    //         };

    //         const rzp1 = new window.Razorpay(options);

    //         rzp1.on('payment.failed', function (response) {
    //             toast(response.error.code);
    //             toast(response.error.description);
    //             toast(response.error.source);
    //             toast(response.error.step);
    //             toast(response.error.reason);
    //             toast(response.error.metadata.order_id);
    //             toast(response.error.metadata.payment_id);
    //         });

    //         rzp1.open();
    //         e.preventDefault();
    //     } catch (error) {
    //         console.error('Payment failed:', error);
    //         // Handle payment failure
    //         toast.error('Payment Not Done');
    //     }
    // };


    const handleRegister = async () => {

        try {

            setLoading(true);


            // Validate if any required field is empty
            const requiredFields = {
                ...formData.userDetails,

            };

            //Generate custom order ID using current date and time

            const donationId = generateDonationId();

            formData.paymentDetails.donationId = donationId;


            if (Object.values(requiredFields).some(value => value === '')) {
                toast.error('All Fileds are Required!')
                setLoading(false)
                return
            }

            // if (selectedFileProfilePic === null || selectedFileAadhar === null) {
            //     toast.error('Please select Aadhar and Profile pictures');
            //     setLoading(false);
            //     return;
            // }

            // if (selectedFileTransaction === null) {
            //     toast.error('Please screenshot');
            //     setLoading(false);
            //     return;
            // }

            if (selectedFileNewProfilePic === null) {
                toast.error('Choose Profile Pic');
                setLoading(false);
                return;
            }

            // Check if user already exists
            const userData = await VerifyUserData(formData.userDetails.email, formData.userDetails.contactNo);

            if (userData) {
                setLoading(false);
                toast.error('Already Registered');
                return;
            }

            await handleFileUpload();


            // Proceed with the registration process
            await createRegistration(formData);

            setLoading(false)

            toast.success('Registration successful!');

            // Delete saved formData from localStorage
            localStorage.removeItem('formData');

            //console.log('new form', formData);

            setTimeout(() => {
                navigate('/login');
            }, 1000); // Adjust the delay time as needed (in milliseconds)


        } catch (error) {

            console.error('Error during registration:', error.message);
            // Handle registration failure or display error message
            toast.error('Registration failed. Please try again.');

            setLoading(false)
        }
    };


    const getStepContent = (stepIndex) => {

        switch (stepIndex) {

            case 0:

                return (
                    <StepOne
                        formData={formData}
                        setFormData={setFormData}
                        handleInputChange={handleInputChange}

                    />
                );

            case 1:
                return (
                    <StepTwo
                        formData={formData}
                        setFormData={setFormData}
                        handleInputChange={handleInputChange}
                        setSelectedOccupation={setSelectedOccupation}
                        selectedOccupation={selectedOccupation}
                        setSelectedGender={setSelectedGender}
                        selectedGender={selectedGender}
                        selectedState={selectedState}
                        setSelectedState={setSelectedState}

                    />
                );
            // case 2:
            //     return (
            //         <StepThree

            //             formData={formData}
            //             setFormData={setFormData}
            //             handleInputChange={handleInputChange}

            //             childOneSelectedGender={childOneSelectedGender}
            //             setChildOneSelectedGender={setChildOneSelectedGender}

            //             childTwoSelectedGender={childTwoSelectedGender}
            //             setChildTwoSelectedGender={setChildTwoSelectedGender}

            //             childThreeSelectedGender={childThreeSelectedGender}
            //             setChildThreeSelectedGender={setChildThreeSelectedGender}
            //         />
            //     );

            case 2:
                return (
                    <NewStep

                        setSelectedFileNewProfilePic={setSelectedFileNewProfilePic}
                    />
                );

            // case 2:
            //     return (
            //         <StepFour

            //             setSelectedFileAadhar={setSelectedFileAadhar}
            //             setSelectedFileProfilePic={setSelectedFileProfilePic}
            //             selectedFileAadhar={selectedFileAadhar}
            //             selectedFileProfilePic={selectedFileProfilePic}


            //         />
            //     );

            // case 4:
            //     return (
            //         <StepFive

            //             selectedFileTransaction={selectedFileTransaction}
            //             setSelectedFileTransaction={setSelectedFileTransaction}


            //         />
            //     );

            default:
                return 'Unknown stepIndex';
        }
    };


    return (


        <div style={{
            textAlign: 'center', marginTop: '100px',
            paddingLeft: '10px', paddingRight: '10px', marginBottom: '100px', position: 'relative'
        }}>

            <Stepper activeStep={activeStep} alternativeLabel style={{ marginBottom: '30px', marginTop: '10px' }}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={CustomStepIcon}>{ }</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Typography variant="h6" align="center" gutterBottom>
                {getStepContent(activeStep)}
            </Typography>


            <Box display="flex" justifyContent="space-between" mt={2}>
                <Button disabled={activeStep === 0} onClick={handleBack} style={{ background: '#ff7722', color: 'white' }}>
                    Back
                </Button>
                <div>

                    {activeStep !== steps.length - 1 ? (
                        <Button variant="contained" onClick={handleNext} style={{ background: '#ff7722' }}>
                            Next
                        </Button>
                    ) : (
                        <Button variant="contained" onClick={handleRegister} style={{ background: '#ff7722' }}>
                            Submit
                        </Button>
                    )}
                </div>


            </Box>

            <div style={{ marginTop: '16px' }}>
                <Typography variant="body1">
                    Already have an account?{' '}
                    <NavLink to="/login" style={{ textDecoration: 'none', color: '#ff7722' }}>
                        Login
                    </NavLink>
                </Typography>

            </div>

            <ToastContainer position="top-right" />

            {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
                    zIndex: 9998, // Ensure it's above other elements but below CircularProgress
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <CircularProgress style={{ color: '#ff7722' }} />
                </div>
            )}


        </div>
    );
};

export default Register;
