import React from 'react'

function PrivacyPolicy() {
    return (

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '90vh' }}>
            <div style={{ fontSize: '30px' }}>Privacy Policy</div>
        </div>

    )
}

export default PrivacyPolicy