import React, { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import { TextField, Button,CircularProgress } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
//import { loginUser } from '../service/client-api';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ForgetPassword = () => {


    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        
        // Retrieve the JWT token from localStorage
        const token = localStorage.getItem('token');

        
        // Redirect to login page if token is not present
        if (token) {
            navigate('/');
            return;
        }


    }, [navigate]);
    


    const handleLogin = async () => {

        try {

            setLoading(true)

            //const response = await loginUser(email); // Call loginUser API function
            const response = ''; // Call loginUser API function
            
            const { token } = response;

            // Store JWT token in local storage
            localStorage.setItem('token', token);

            // Decode JWT token to access user data (e.g., role)
            const decodedToken = jwtDecode(token);
            const userRole = decodedToken.role;

            // Redirect to home page based on user role
            if (userRole === 'admin') {
                navigate('/admin');

            } else {
                navigate('/');
            }

            setLoading(false)

            
        } catch (error) {
            
            setLoading(false)
            console.error('Error during login:', error);
            //window.alert('Invalid username or password');
            toast.error('Invalid username or password')
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '120px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <TextField
                    label="Email or Phone"
                    variant="outlined"
                    color="warning"
                    style={{ marginBottom: '25px', width: '300px' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    style={{
                        width: '300px',
                        marginTop: '10px',
                        padding: '10px',
                        background: 'linear-gradient(to right, #ff7722, #ffaa44)',
                    }}>
                    Login
                </Button>
                <div>
                    <p>Don't have an account yet? <NavLink to="/register" style={{ textDecoration: 'none' }}><span style={{ color: '#ff7722' }}>Register</span></NavLink></p>
                    <p><NavLink to="/login" style={{ textDecoration: 'none' }}><span style={{ color: '#ff7722' }}>Login</span></NavLink></p>
                </div>
                
            </div>

            <ToastContainer position="top-right" />
            {loading && <CircularProgress style={{ color: '#ff7722' }} />}

        </div>
    );
};

export default ForgetPassword;
